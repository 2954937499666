<template>
	<div
		class="btn offer"
		:class="buyInOfferSelected ? 'buyInOfferSelected' : ''"
		@click="selectThisBuyInOffer($event)"
	>
		<h1>{{ buyInOffer.name }}</h1>
		<div>
			<p class="flex-column">
				<span>{{ buyInCashString() }} {{ languageStrings.cashBuys }}</span>
				<span
					>{{
						inGameFormatAUasCurrency(
							buyInOffer.creditRegularAUPerCashCOC,
							systemCurrencyTool.displayType.minorWholeOrFull
						)
					}}
					{{ languageStrings.regularCredit }}</span
				>
				<span
					>{{ languageStrings.and }}
					{{
						inGameFormatAUasCurrency(
							this.buyInOffer.creditPromoAUPerCashCOC,
							systemCurrencyTool.displayType.minorWholeOrFull
						)
					}}
					{{ languageStrings.promo }}</span
				>
			</p>
			<p>
				<small>
					<span
						>{{ languageStrings.min }}:
						{{ systemFormatCurrency(buyInOffer.minimumCOC, systemCurrencyTool.displayType.minorOrFull) }} |
						{{ languageStrings.max }}:
						{{
							systemFormatCurrency(buyInOffer.maximumCOC, systemCurrencyTool.displayType.minorOrFull)
						}}</span
					>
				</small>
			</p>
		</div>
		<CommitBuyInOffer
			:buyInOfferSelected="buyInOfferSelected"
			:playerState="playerState"
			:buyInOffer="buyInOffer"
			:inGameCurrencyTool="inGameCurrencyTool"
			:systemCurrencyTool="systemCurrencyTool"
			:languageStrings="languageStrings"
			:languageErrorStrings="languageErrorStrings"
			:webPayOptions="webPayOptions"
			:isMobile="isMobile"
			:restrictedNeedsMoreInfo="restrictedNeedsMoreInfo"
		/>
	</div>
</template>

<script>
import CurrencyTool from "@/dependencies/currencyTool";
import CommitBuyInOffer from "@/components/CommitBuyInOffer.vue";
import { onBeforeUnmount } from "vue";

export default {
	name: "BuyInOffer",
	inheritAttrs: false,
	props: {
		playerState: Object,
		buyInOffer: Object,
		// inGameCurrencyTool: Object,
		systemCurrencyTool: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
		systemSettings: Object,
		webPayOptions: Object,
		inGameCurrencyInfo: Object,
		casinoList: Array,
		isMobile: Boolean,
		restrictedNeedsMoreInfo: Boolean,
	},
	components: {
		CommitBuyInOffer,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			cashOutCurrency: this.buyInOffer.creditPromoAUPerCashCOC,
			systemCurrencyInfo: this.buyInOffer.cashOutCurrency,
			buyInOfferSelected: false,
			inGameCurrencyTool: new CurrencyTool(this.inGameCurrencyInfo, this.localeString),
		};
	},
	created() {
		this.eventBus.on("nuveiChecoutResult", () => {
			this.buyInOfferSelected = false;
		});
		this.eventBus.on("deselectBuyInOffer", (buyInId) => {
			// Deselects all buy-ins other than the id specified in the payload for this event.
			// Pass null in the payload for this event to deselect all buy-in offers.
			if (buyInId != this.buyInOffer.id) this.buyInOfferSelected = false;
		});
		onBeforeUnmount(() => {
			this.eventBus.on("nuveiChecoutResult");
			this.eventBus.off("deselectBuyInOffer");
		});
	},
	methods: {
		selectThisBuyInOffer() {
			this.eventBus.emit("deselectBuyInOffer", this.buyInOffer.id);
			this.buyInOfferSelected = true;
		},
		systemFormatCurrency(valueCOC, display = {}) {
			return this.systemCurrencyTool.formatCurrency(valueCOC, display);
		},
		inGameFormatAUasCurrency(valueCOC, display = {}) {
			return this.inGameCurrencyTool.formatFromAU(valueCOC, display);
		},
		buyInCashString() {
			const compareFactor = this.systemCurrencyTool.currencyInfo.minorDigits < 1 ? 100 : 1;
			return this.systemCurrencyTool.formatCurrency(
				compareFactor,
				this.systemCurrencyTool.displayType.minorWholeOrFull
			);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
small {
	display: block;
	text-align: center;
}

h1 {
	line-height: 1.125em;
}

.flex-column {
	display: flex;
	flex-flow: column nowrap;
}

p.flex-column {
	text-align: center;
}

.btn:hover {
	transform: none;
}

.offer {
	margin-bottom: 15px;
}
.buyInOfferSelected {
		background-image: url("../../public/styleOverrides/encore_online_pixel_bg.png");
}

@media (max-width: 768px) {
	.buyInOfferSelected {
		color: #fff;	
	}
}
</style>
